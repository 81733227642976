<template>
  <v-card
    @click="goto(model.id)"
    elevation="4"
    class="pl-3 my-2 pr-1 pointer card__search"
  >
    <v-row class="px-2" justify="space-between" style="color: #5e616f">
      <v-col
        cols="7"
        md="9"
        lg="10"
        class="d-flex flex-column"
        style="padding: 8px; position: relative"
      >
        <v-layout wrap>
          <v-flex xs12 class="font-18 font-w-500 search__topic ell-2">
            {{ model.topic }}
          </v-flex>
          <v-flex xs12 class="d-flex align-end">
            <div v-if="link == 'media'" class="overflow-ellipsis-2 font-12">
              <div>
                <i class="bi bi-calendar-minus"></i> อัปเดตล่าสุด
                {{ model.release_date | moment("DD/MM/YYYY") }}
              </div>
            </div>
            <div v-if="link == 'course'" class="overflow-ellipsis-2 font-12">
              <div>
                {{ model.name_lecturer }}
              </div>
            </div>
            <div v-else class="ell-2 font-12">
              <!-- {{ model.content.replace("?", "...") }} -->
              <div class="ell-2 font-12 v-html" v-html="model.content"></div>
            </div>
          </v-flex>
        </v-layout>
      </v-col>
      <v-col cols="5" md="3" lg="auto">
        <div class="d-flex justify-end">
          <v-img
            v-if="link === 'media'"
            min-height="84"
            min-width="63"
            style="border-radius: 5px"
            :aspect-ratio="1"
            :src="checkImage(model.image)"
            contain
            alt=""
          ></v-img>

          <v-img
            v-else
            min-height="83"
            min-width="149"
            style="border-radius: 5px"
            :aspect-ratio="16 / 9"
            :src="checkImage(model.image)"
            alt=""
          ></v-img>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from "moment";
import { storage_path } from "../../util/Constant";
export default {
  props: ["model", "link", "width", "height"],
  data() {
    return {
      moment: moment,
    };
  },
  methods: {
    goto(id) {
      this.$router.push(`/${this.link}/${id}`);
    },
    checkImage(img) {
      var string = "https://";
      var image = "";
      if (img.includes(string)) {
        image = img;
      } else {
        image = storage_path + img;
      }
      return image;
    },
    checkLabel(date) {
      if (date > moment().format("YYYY-mm-dd")) {
        return "Upcoming!";
      } else if (date === moment().format("YYYY-mm-dd")) {
        return "NEW!";
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss">
.card__search:hover {
  .search__topic {
    color: #4090ff;
  }
}
</style>

<style scoped>
.v-html >>> img {
  width: 100% !important;
  height: auto !important;
}
.v-html >>> p {
  margin-bottom: 0px !important;
  font-size: 12px !important;
}
.v-html >>> span {
  margin-bottom: 0px !important;
  font-size: 12px !important;
}
.v-html >>> iframe {
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 1.78341013825;
}
@media only screen and (max-width: 768px) {
  .v-html >>> img {
    width: 100% !important;
    height: auto !important;
  }
  .v-html >>> iframe {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 1.78341013825;
  }
}
.card_label {
  position: absolute;
  top: 0;
  z-index: 1;
  background: aquamarine;
  border-radius: 5px;
  width: 65px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
